import React, { useState } from 'react';
import Slider from './../assets/Slider-1.png';
import Slider1 from './../assets/Slider.png';
import './Home.css';

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    console.log('Email changed:', e.target.value);
  };

  const handleFormSubmit = (e) => {
    //Process the email here
    fetch('http://localhost:3000/subscribe', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        "email" : email }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        // Optionally, close the modal after submission
        //setIsModalOpen(false);
      })
      .catch((error) => {
        console.error('Error:' );
      });
  };
  return (
    <div className="container">
      <div className="header-container">
      <div className="header">censocial</div>
      <div className="subheader">cut the clutter</div>
      <button className="download-button" onClick={openModal}>
        <span className="download-text">download</span>
        <span className="price-text">$9.99/mo</span>
      </button>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2 className="modal-header">hey, thanks for your interest!</h2>
            <p className="modal-body">
              We are having a blast working on building censocial for you and your friends. It means so much to us that you are interested in downloading and becoming a part of the community!
              <br /><br />
              If you would like to receive updates on our progress and get to know more about us, feel free to drop us your email below.
            </p>
            <div className="modal-input-container" >
              <input type="email" className="modal-input" placeholder="your email" value={email}
                onChange={handleEmailChange}/>
              <button className="modal-button" onClick={handleFormSubmit}>sign me up</button>
            </div>
          </div>
        </div>
      )}

      <div className="split-container">
        <div className="left-half">
        <p className="mini-header-text">Stay up to date without sacrificing your time and peace</p>
          <h1 className="left-header">All of your social media accounts in one place</h1>
          <div className="left-row">
          <p className="left-body-text">censocial creates a lightweight social media experience by giving users access to highlights and messaging within a beautifully designed interface.</p>
          <button className="download-button-card" onClick={openModal}>
            <span className="download-text">download</span>
            <span className="price-text">$9.99/mo</span>
          </button>
          </div>
        </div>
        <div className="right-half">
        <img src={Slider} alt="Slider" className="slider-image1" />
        <img src={Slider1} alt="Slider 1" className="slider-image2" />

        </div>
      </div>
      <div className="copyright">© 2024 Alyssa Gillen</div>

    </div>
  );
}

export default Home;
